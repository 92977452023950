<template>
  <div class="">
    <a-spin :spinning="loading">
      <div class="flex space alcenter">
        <div class="ft20 cl-main ftw600">{{getTitle}}</div>
        <a-button @click="$router.go(-1)">返回</a-button>
      </div>

      <div class="mt20 bg-w pd40">

        <div style="width: 1000px;">
          <a-alert class="mb20" message="系统将会根据所填手机号自动生成长者账号，若手机号已存在，则会将该长者绑定到该账号下" banner />

          <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-divider orientation="left">基础档案</a-divider>
            <a-row>
              <a-col :span="12">
                <a-form-model-item required label="姓名">
                  <a-input v-model="form.name" :maxLength="30" placeholder="输入长者名称" style="width: 220px;"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="别名">
                  <a-input v-model="form.alias_name" placeholder="请输入别名" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item required label="手机号码">
                  <a-input v-model="form.mobile" placeholder="请输入手机号码" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="固定电话">
                  <a-input v-model="form.phone" placeholder="请输入固定电话" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="性别" >
                  <a-radio-group v-model="form.gender">
                    <a-radio :value="0">男</a-radio>
                    <a-radio :value="1">女</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>

              <a-col :span="12">
                <a-form-model-item required label="身份证号">
                  <a-input v-model="form.id_card" placeholder="请输入身份证号" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="服务管家" >
                  <a-tree-select v-model="form.serviceman_id" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.servicemans"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="随访频率" help="单位：天；0 则使用系统配置频率">
                  <a-input v-model="form.access_rate" placeholder="请输入随访频率" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="户籍地址" >
                  <a-cascader style="width: 220px;"
                      v-model="form.domiciles"
                      :options="regiondata"
                      :default-value="form.domiciles"
                      placeholder="请选择户籍地址"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="户籍详址">
                  <a-input v-model="form.dom_address" placeholder="请输入户籍详细地址" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="现居地址" >
                  <a-cascader style="width: 220px;"
                        v-model="form.home"
                        :options="regiondata"
                        :default-value="form.home"
                        placeholder="请选择现居地址"
                        @change="changeHome"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="请选择街道">
                  <a-cascader style="width: 220px;" v-if="streetsdata.length>0"
                    v-model="form.home_street"
                    :options="streetsdata"
                    :default-value="form.home_street"
                    placeholder="请选择街道数据"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="现居详址">
                  <a-input v-model="form.home_address" placeholder="请输入现居详细地址" @change="homeAddresshange" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item required label="详址定位">
                  <div class="flex alcenter">
                    <a-input :value="home_lat + ',' + home_lng" disabled style="width: 200px; margin-right: 10px; color: #000000;"></a-input>
                    <baidu-map :lat="home_lat" :lng="home_lng" :keyword1="form.home_address" @select="selectAct"></baidu-map>
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="照料人" >
                  <a-tree-select v-model="form.guardian_type" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.guardianType"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="个人特长">
                  <a-input v-model="form.speciality" placeholder="请输入个人特长" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="民族">
                  <a-input v-model="form.nation" placeholder="请输入民族" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="婚姻状况" >
                  <a-tree-select v-model="form.marriage" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.maritalType"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="文化程度" >
                  <a-tree-select v-model="form.education" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.education"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="政治面貌" >
                  <a-tree-select v-model="form.politics" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.politics"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="职业" >
                  <a-tree-select v-model="form.career" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.career"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="性格" >
                  <a-input v-model="form.character" placeholder="请输入性格" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="爱好">
                  <a-input v-model="form.interest" :maxLength="30" placeholder="输入长者爱好" style="width: 220px;"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="子女数">
                  <a-input-number v-model="form.children_num" placeholder="请输入子女数" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="健在" >
                  <a-radio-group v-model="form.if_exist">
                    <a-radio :value="0">否</a-radio>
                    <a-radio :value="1">是</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="经济来源" >
                  <a-tree-select v-model="form.financial" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.financial"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="救助类型" >
                  <a-tree-select v-model="form.rescue" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.rescue"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="医保情况" >
                  <a-tree-select v-model="form.guarantee" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.guarantee"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="头像" >
                  <div class="ft12 ftw400 cl-notice">建议上传300x240px</div>
                  <div class="mt10 ml20">
                    <upload-img v-model="form.cover_img"></upload-img>
                  </div>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="重阳分">
                  <a-radio-group v-model="form.if_cyf">
                    <a-radio :value="0">无</a-radio>
                    <a-radio :value="1">有</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-divider orientation="left">居住情况</a-divider>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="居住情况" >
                  <a-tree-select v-model="form.live_type" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.liveType"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="同住情况" >
                  <a-tree-select v-model="form.family_type" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.familyType"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="养老方式" >
                  <a-tree-select v-model="form.retirement" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.retirement"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="燃料类型" >
                  <a-tree-select v-model="form.fuel_type" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.fuelType"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="饮水类型" >
                  <a-tree-select v-model="form.water_type" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.waterType"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="厨房排风" >
                  <a-tree-select v-model="form.kitchen_type" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.kitchenType"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="宠物" >
                  <a-tree-select v-model="form.pet_type" style="width: 200px"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="baseInfo.petType"
                                 placeholder="请选择" >
                  </a-tree-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">

              </a-col>
            </a-row>

            <a-divider orientation="left">监护人信息</a-divider>

            <a-row>
              <a-col :span="24">
                <a-form-model-item label="监护人">
                  <a-radio-group v-model="form.is_have_jhr">
                    <a-radio :value="0">无</a-radio>
                    <a-radio :value="1">有</a-radio>
                  </a-radio-group>

                  <div class="mt10" v-if="form.is_have_jhr==1">
                    <table class="wxb-table list" style="width: 100%;">
                      <thead>
                        <th>姓名</th>
                        <th>关系</th>
                        <th>电话</th>
                        <th>默认</th>
                        <th>居住地址</th>
                        <th>操作</th>
                      </thead>
                      <tbody>
                        <tr v-for="(item,index) in form.jhr">
                          <td><a-input style="width:100px;" placeholder="请输入姓名" v-model="item.name"></a-input></td>
                          <td>
                            <a-tree-select v-model="item.guanxi" style="width:80px" :tree-data="baseInfo.guardianType"
                                           placeholder="请选择" >
                            </a-tree-select>
                          </td>
                          <td><a-input style="width:140px;" placeholder="请输入电话" v-model="item.tel"></a-input></td>
                          <td><a-switch size="small" v-model="item.status_text" /></td>
                          <td><a-input placeholder="请输入居住地址" v-model="item.address"></a-input></td>
                          <td>
                            <div class="clickAct" @click="delSkuItem(index)">
                              <i class="iconfont iconbtn_close ft20 cl-notice "/>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="">
                      <a-button type="primary" icon="plus" ghost @click="addSkuItem()">添加</a-button>
                    </div>
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>

            <div class="edit-project-line mb40"></div>

            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
                确定保存
              </a-button>
            </a-form-model-item>
          </a-form-model>

        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import uploadImg from '../../components/upload/upload.vue';
import BaiduMap from '../../components/map/baidumap.vue';
export default{
  components:{
    uploadImg,
    BaiduMap
  },
  data(){
    return {
      loading:false,
      confirmLoading:false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      id:0,
      baseInfo:[],
      regiondata:[],
      streetsdata:[],
      home_lat:0,
      home_lng:0,
      form: {
        name:'',
        alias_name:'',
        mobile:'',
        phone:'',
        id_card:'',
        marriage:'',
        gender:'',
        dom_address:'',
        home_street:[],
        home_address:'',
        guardian_type:'',
        speciality:'',
        nation:'',
        education:'',
        politics:'',
        serviceman_id:'',
        access_rate:0,
        career:'',
        character:'',
        interest:'',
        children_num:'',
        if_exist:1,
        financial:'',
        rescue:'',
        guarantee:'',
        cover_img:'',
        live_type:'',
        family_type:'',
        if_cyf:'',
        retirement:'',
        fuel_type:'',
        water_type:'',
        kitchen_type:'',
        pet_type:'',
        jhr:[],
        is_have_jhr:0,
        home_lat:0,
        home_lng:0,
      }
    }
  },
  created() {
    if (!this.$route.query.id) {
      this.id=0;
    } else {
      this.id = parseInt(this.$route.query.id);
    }
    this.loaddata();
  },
  computed:{
    getTitle(){
      if(this.id==0){
        return '添加长者';
      }else{
        return '编辑长者';
      }
    }
  },
  methods:{
    loaddata(){
      if(this.loading==true) return;
      this.loading=true;
      this.$http.api('admin/elderBaseInfo',{}).then(res=>{
        if(res.detail){
          this.form=res.detail;
        }
        this.baseInfo=res.base;
        this.loading=false;
      }).catch(res=>{
        this.loading=false;
        this.$router.go(-1);
      });

      this.$http.api('admin/regionList',{}).then(res=>{
        this.regiondata=res.lists;
      }).catch(res=>{
        this.$router.go(-1);
      });

      this.$http.api('admin/showElder',{
        id:this.id
      }).then(res=>{
        if(res.detail){
          this.form=res.detail;
          this.home_lat = this.form.home_lat;
          this.home_lng = this.form.home_lng;
          this.streetsdata = res.streetdata;
        }
        this.loading=false;
      }).catch(res=>{
        this.loading=false;
        this.$router.go(-1);
      })

    },

    onSubmit() {
      if(this.confirmLoading==true) return;
      this.confirmLoading=true;

      let arr = this.form.jhr;
      if(arr.length>0){
        for (let i=0; i<arr.length; i++){
          this.form.jhr[i]['status'] = arr[i]['status'] ? 1 :0;
        }
      }

      this.form.home_lat = this.home_lat;
      this.form.home_lng = this.home_lng;

      this.$http.api('admin/saveElder',{
        params:this.form,
        id:this.id,
      }).then(res=>{
        this.$message.success('保存成功',1,()=>{
          this.confirmLoading=false;
          this.$router.go(-1);
        });
      }).catch(res=>{
        this.confirmLoading=false;
      })
    },

    addSkuItem(){
      this.form.jhr.push({
        name:'',
        guanxi:'',
        tel:'',
        status:'',
        address:'',
      })
    },

    delSkuItem(index){
      this.form.jhr.splice(index,1);
    },

    selectAct(e){
      this.home_lat = e.lat;
      this.home_lng = e.lng;
    },
    homeAddresshange(e){
      this.form.home_address = e.target.value;
    },
    changeHome(selectedOptions){
      let district = selectedOptions[2];
      if(district != 'undefined'){
        this.$http.api('admin/getStreets',{
          district:district
        }).then(res=>{
          this.streetsdata=res.lists;
        }).catch(res=>{
          this.$router.go(-1);
        })
      }
    },
    selHomeStreet(){
      console.log(123);
    },

  }
}
</script>

<style>
.edit-project-line{
  width: 100%;
  height: 1px;
  background: #EBEDF5;
}

.edit-project-details-text {
  width: 120px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #5333FF;
}

.mobile-content{
  width: 750px;
}
.mobile-content{

}

.mobile-item{
  position: relative;
}
.mobile-item .action{
  position: absolute;
  left: 0;
  top: -15px;
  width: 100%;
  z-index: 2;
}
.del-action{
  width: 30px;
  height: 30px;
  background: rgba(0,0,0,.5);
  text-align: center;
  line-height: 30px;
  border-radius: 15px;
  color:#FFFFFF;
  position: absolute;
  right: -15px;
  top:-15px;
  z-index: 3;
}
.up-action,.down-action{
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  text-align: center;
  line-height: 30px;
  border-radius: 15px;
  color:#007AFF;
  box-shadow:0px 2px 8px 0px rgba(0,0,0,0.08);

}
</style>